<template>
  <section class="">
    <b-row>
      <b-col sm="6" lg="4" v-for="nft in portfolio" :key="nft.id">
        <!-- <h5>{{ nft.id }}</h5>
        <h5>{{ nft.leases[0].address }}</h5> -->
        <card-nft-summary :lease="getLeaseObj(nft.leases[0])" :nft="nft" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";

// import store from "@/store";
import { sync } from "vuex-pathify";
import CardNftSummary from "@/views/card/app-custom/card-nft-summary.vue";

import { getArrElemById } from "@/libs/helpers";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BLink,
    BButton,

    // custom cards
    CardNftSummary,
  },
  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),
    nfts: sync("nfts/list"),
    portfolio: sync("nfts/portfolio"),
  },

  watch: {
    portfolio(newValue) {
      console.log("nft port updated: ", newValue);
    },
  },
  data() {
    return {};
  },
  methods: {
    getLeaseObj(lease) {
      if (lease && lease.id) {
        return getArrElemById(this.leases, "id", lease.id);
      } else {
        return null;
      }
    },
    userHasHolding(nft) {
      // if (this.user && this.user.id) {
      //   for (let i=0; i<=nft.holdings.length; i++) {
      //     if (this.user.id == ) {
      //       return true;
      //     }
      //   }
      // }
      return true;
    },
  },
  setup() {},
  mounted() {},
  created() {},
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
